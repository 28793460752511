<template>
    <b-overlay :show="loading">
      <form-main @submit="submit" />
        <b-card
        >
          <b-row class="justify-content-between">
            <b-col md="6" sm="12" class="my-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click.prevent="add"
                variant="primary"
                v-if="allowCreate()"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                Tambah
              </b-button>
              
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
      
            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="repairAssets"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
              >
                <template #cell(order)="row">
                  <strong class="text-center">
                    {{ (row.index + 1) }}
                  </strong>
                </template>
                <template #cell(gm_approve)="{item}">
                  <p class="text-center">
                    <feather-icon class="w-full text-center" size="24" :icon="item.gm_approve == 1 ? 'CheckCircleIcon' : 'XCircleIcon'" :class="item.gm_approve == 1 ? 'text-success' : 'text-danger'"></feather-icon>
                  </p>
                </template>
                <template #cell(finance_approve)="{item}">
                  <p class="text-center">
                    <feather-icon class="w-full text-center" size="24" :icon="item.finance_approve == 3 ? 'CheckCircleIcon' : 'XCircleIcon'" :class="item.finance_approve == 3 ? 'text-success' : 'text-danger'"></feather-icon>
                  </p>
                </template>

                <template #cell(total)="{item}">
                  Rp {{ formatRupiah(item.total) }}
                </template>
      
                <template #cell(actions)="{item}">
                    <b-button variant="outline-info" class="mr-1" size="sm" @click.prevent="$router.push(`/asset-disposal/perbaikan/detail/${item.id}`)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button v-if="allowUpdate() && (!item.gm_approve && !item.finance_approve)" variant="outline-info" class="mr-1" size="sm" @click.prevent="edit(item.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                      <b-button
                        v-if="allowDelete() && (!item.gm_approve && !item.finance_approve)"
                        v-b-tooltip.hover.right="'Hapus'"
                        size="sm"
                        @click="remove(item)"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                </template>
              </b-table>
            </b-col>
      
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
    </b-overlay>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import FormMain from './components/FormMain.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
BTable,
BFormTextarea,
BOverlay,
VBTooltip,
BDropdown,
BDropdownItem,
BAvatar,
BBadge,
BModal,
BRow,
BCard,
BCol,
BFormGroup,
BFormSelect,
BPagination,
BInputGroup,
BFormInput,
BInputGroupAppend,
BButton,
BFormCheckbox,
} from "bootstrap-vue"
export default {
    components: {
      FormMain,
        BCard,ToastificationContent,
        VBTooltip,
        BModal,
        BDropdown,
        BDropdownItem,
        BTable,
        BFormTextarea,
        BOverlay,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BFormCheckbox
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data: () => ({
        id: null,
        fields: [
            { key: 'order', label: 'No' },
            { key: 'no', label: 'No. Perbaikan' },
            { key: 'tanggal', label: 'Tanggal Perbaikan' },
            { key: 'judul', label: 'Judul' },
            { key: 'gm_approve', label: 'Approval GM' },
            { key: 'finance_approve', label: 'Approval Finance' },
            { key: 'actions', label: 'Aksi' }
        ],
        loading: false,
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        repairAssets: [],
        confirmOption: {
          title: 'Anda yakin?',
          text: `Data disposal ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }
    }),
    watch: {
      "$route.path"(val) {
        this.getPerbaikan()
      }
    },
    methods: {
      setFields() {
        if(this.isOwner || this.isGM) {
          this.fields = [
          { key: 'order', label: 'No' },
            { key: 'no', label: 'No. Perbaikan' },
            { key: 'tanggal', label: 'Tanggal Perbaikan' },
            { key: 'judul', label: 'Judul' },
            { key: 'gm_approve', label: 'Approval GM' },
            { key: 'total', label: 'Total Perbaikan' },
            { key: 'actions', label: 'Aksi' }
          ]
        }
        else if(this.isFinance) {
          this.fields = [
            { key: 'order', label: 'No' },
            { key: 'no', label: 'No. Perbaikan' },
            { key: 'tanggal', label: 'Tanggal Perbaikan' },
            { key: 'judul', label: 'Judul' },
            { key: 'gm_approve', label: 'Approval GM' },
            { key: 'finance_approve', label: 'Approval Finance' },
            { key: 'total', label: 'Total Perbaikan' },
            { key: 'actions', label: 'Aksi' }
          ]

        }
      },
      add() {
        this.id = null
        this.$store.commit('asset/SET_CURRENT_PERBAIKAN', null)
        this.$bvModal.show('form-perbaikan-modal')
      },
      edit(id) {
        this.id = id
        const perbaikan = this.repairAssets.find(item => item.id == id)
        if(perbaikan) {
          this.$store.commit('asset/SET_CURRENT_PERBAIKAN', perbaikan)
          this.$bvModal.show('form-perbaikan-modal')
        }
      },
      async submit(data) {
        const payload = Object.assign({}, data)
        if(this.id) {
          payload.id = this.id
        }

        if(this.myGudang ) {
          payload.id_gudang = this.myGudang.id
        }

        this.loading = true
        this.$bvModal.hide('form-perbaikan-modal')
        try {
          const perbaikan = await this.$store.dispatch('asset/savePerbaikan', [payload])
          this.loading = false
          
          this.displaySuccess({
            message: 'Perbaikan berhasil disimpan'
          })

          this.$store.commit('asset/SET_CURRENT_PERBAIKAN', null)

          if(!this.id)  {
            this.id = null
            this.$router.push(`/asset-disposal/perbaikan/detail/${perbaikan.id}`)
          }
          else {
            this.id = null
            setTimeout(() => {
              this.getPerbaikan()
            })
          }

        }
        catch(e) {
          this.$bvModal.show('form-perbaikan-modal')
          this.displayError(e)
          return false
        }
      },
      async printDisposal({tgl_awal, tgl_akhir}) {
          const params = {
              jenis: this.$route.meta.jenis,
              tgl_awal,
              tgl_akhir
          }
          if(this.myGudang) params.id_gudang = this.myGudang.id

          try {
            this.loading = true
            this.$bvModal.hide('print-modal')
            const response = await this.$store.dispatch('asset/printDisposal', params)
            this.loading = false
            window.open(URL.createObjectURL(response))
          }
          catch(e) {
            this.loading = false
            this.displayError(e)
            return false
          }
      },
      remove(item) {
        this.$swal(this.confirmOption)
        .then(async res => {
          if(res.value) {
            const payload = {
              id: item.id,
              fungsi: 2
            }
            try {
              this.loading = true
              await this.$store.dispatch('asset/savePerbaikan', [payload])
              this.displaySuccess({
                message: 'Disposal berhasil dihapus!'
              })
              this.getPerbaikan()
              this.loading = false
            }
            catch(e) {
              this.loading = false
              this.displayError(e)
              return false
            }
          }
        })
      },
        async getPerbaikan() {
            this.loading = true
            const params = {order: 'desc'}
            if(this.myGudang) params.id_gudang = this.myGudang.id
            this.repairAssets = await this.$store.dispatch('asset/getPerbaikan', params)
            this.loading = false
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
      },
    },
    mounted() {
        this.setFields()
        this.getPerbaikan()
    }
}
</script>